import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


/* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        cursor: "pointer",
        "& :hover": {
            opacity: 0.8,
            transition: "opacity 0.2s"
        }
    },
    inner: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
    dark: {
        opacity: 0.3,
        transition: "opacity 0.2s"
    }
}))

/**
 *
 *
 */
const Ratio = ({ paddingBottom = 56.25, dark = true, background= '#333', children }) => {
    const classes = useStyles()
    if (children) {
        return <div className={classes.root + ' ratio'} style={{ paddingBottom: paddingBottom + "%", backgroundColor:background}}>
            <div className={classes.inner}>
                {children}
            </div>
        </div>
    }
    else return <div className={classes.root + ' ratio'} style={{ paddingBottom: paddingBottom + "%", backgroundColor:background}}>
        <div className={classes.inner}>
        </div>
    </div>
}


export default Ratio;
