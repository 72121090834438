import React, { Component } from 'react';
import './App.scss';
import 'typeface-roboto';

//material
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import AfficheImage from 'components/AfficheImage';
import Ratio from 'components/Ratio';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const theme = createMuiTheme({
    palette: {
        primary: {
          main: '#E5322D',
        },
    },
    typography: {
      // In Chinese and Japanese the characters are usually larger,
      // so a smaller fontsize may be appropriate.
      fontSize: 16,
    },
});
theme.typography.h1={
  fontSize: 30,
  marginTop:30,
  marginBottom:30,
};
theme.typography.h2={
  fontSize: 28,
  marginTop:15,
  marginBottom:10,
};
theme.typography.h3={
  fontSize: 24,
  marginTop:15,
  marginBottom:5,
};
theme.typography.body1={
  marginTop:10,
  marginBottom:5,
};
theme.typography.body2={
  marginTop:10,
  marginBottom:5,
  color:'#666',
};

class App extends Component {
    constructor(props){
        super(props);
        this.state={
            scrolled:false
        }
        this.handleScroll=this.handleScroll.bind(this);
        this.ref=React.createRef();
    }
    handleScroll(){
        let anchor=this.ref.current;
        if (window.scrollY>anchor.offsetTop && this.state.scrolled===false) this.setState({scrolled:true});
        if (window.scrollY<=anchor.offsetTop && this.state.scrolled===true) this.setState({scrolled:false});
    }
    componentDidMount(){
        window.addEventListener('scroll',this.handleScroll);
        this.handleScroll();
    }
    componentWillUnmount(){
        window.removeEventListener('scroll',this.handleScroll);
    }
    render(){
        let classes='menu-left';
        if (this.state.scrolled) classes+=' show';
        return <ThemeProvider theme={theme}>
        <Container maxWidth='md'>
            <div className="spacer"></div>
            <Grid container justify='center'>
                <Grid item xs={7}>
                    <img alt="passerelle" style={{width:'100%'}} src='/assets/passerelle.svg'/>
                </Grid>
            </Grid>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <Typography variant="h1">
            Nuit des chercheurs - 27 novembre 2020 - Albi
            </Typography>
            <Typography variant="h3">
            Passerelle Arts Sciences Technologies
            </Typography>
            <Typography variant="subtitle1">
            avec le soutien du Ministère de la Culture
            </Typography>
            <Typography variant="h2">
            Sommaire
            </Typography>

            <Paper>
                <MenuList>
                    <MenuItem><a href='#rendez-vous'>Les rendez-vous en direct</a></MenuItem>
                    <Divider/>
                    <MenuItem><a href='#qui-parle-donc'>Qui parle donc ?</a></MenuItem>
                    <MenuItem><a href='#atmosphere'>Atmosphère Primale</a></MenuItem>
                    <Divider/>
                    {/*<MenuItem><a href='#questions'>Vous avez des questions ?</a></MenuItem>*/}
                    <MenuItem><a href='#passerelle'>Présentation de Passerelle</a></MenuItem>
                </MenuList>
            </Paper>
            <Divider/>
            <div className="spacer" ref={this.ref}></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <Divider/>
            <div className="spacer" id='rendez-vous'></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>


            {/*//Direct*/}
            <Typography variant="h2">
            Les rendez-vous en direct :
            </Typography>
            <Typography variant="h3">
            > <a href='#rendez-vous'><strong>RDV à 20H00</strong></a> pour échanger sur le projet <strong>« Atmosphère Primale »</strong>
            </Typography>
            {/*<Typography variant="body1">
            <a href="https://us02web.zoom.us/j/84167550687" target="_blank" rel="noreferrer">Lien zoom vers la visio-conférence</a>
            </Typography>*/}
            <Typography variant="body1">
            <strong>En direct avec Edwige Armand</strong>, Enseignante Chercheuse en Art et Culture, Toulouse INP
            Purpan, Lara-Seppia (UT Jean Jaurès).
            </Typography>
            <Typography variant="body2">
            Docteur en Art, spécialité Art numérique, ses recherches portent sur la construction de la
            réalité au travers des représentations, du langage, de la technique qui par ailleurs modélisent
            les phénomènes vécus.
            </Typography>
            <Typography variant="h3">
            > <a href='#rendez-vous'><strong>RDV à 20H30</strong></a> pour échanger sur la <strong>vie Artificielle</strong>
            </Typography>
            {/*<Typography variant="body1">
            <a href="https://us02web.zoom.us/j/85097086228" target="_blank" rel="noreferrer">Lien zoom vers la visio-conférence</a>
            </Typography>*/}
            <Typography variant="body1">
            <strong>En direct avec Yves Duthen</strong>, Professeur d’informatique, Directeur de recherche à l’IRIT –
            UT Capitole - Toulouse
            </Typography>
            <Typography variant="body2">
            Il a fondé l’équipe Vie Artificielle, domaine de recherche qui étudie et tente de synthétiser des systèmes qui ont des
            propriétés du vivant.
            </Typography>
            <Typography variant="h3">
            > <a href='#rendez-vous'><strong>RDV à 21H00</strong></a> pour échanger sur la <strong>genèse des plantes</strong>
            </Typography>
            {/*<Typography variant="body1">
            <a href="https://us02web.zoom.us/j/81849061361" target="_blank" rel="noreferrer">Lien zoom vers la visio-conférence</a>
            </Typography>*/}
            <Typography variant="body1">
            <strong>En direct avec Antoine Flores</strong>, étudiant en 4eme Année Toulouse INP Purpan
            </Typography>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <Divider/>
            <div className="spacer" id="qui-parle-donc"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>


            {/*Qui parle donc*/}
            <Grid container justify='left'>
                <Grid item xs={4}>
                    <AfficheImage affiche='/qui-parle.jpg' paddingBottom={66.17}/>
                </Grid>
            </Grid>
            <div className="spacer"></div>
            <Typography variant="body1">
            <strong>« Qui parle donc ? »</strong> est une installation art-science qui donne à imaginer la manière
            dont le végétal perçoit son environnement. Elle présente, à la fois, les recherches
            scientifiques en cours sur les capacités de perception du végétal et la manière dont l’art
            peut actualiser le regard que nous portons aux plantes.
            </Typography>
            <Typography variant="body1">
            Concept de l’installation :
            </Typography>
            <Typography variant="body1">
            De nouvelles études dévoilent que l’étendue du spectre perceptif des plantes est vaste.
            Elles sont capables de percevoir d’autres entités vivantes, comme les bactéries ou les
            champignons, leur environnement physique (gravité, lumières, sons), ainsi que leur
            propre posture, leur état de santé.
            </Typography>
            <Typography variant="body1">
            Ces découvertes récentes nous amènent à repenser l’univers du végétal et nous
            confrontent à notre anthropocentrisme. Cette installation s’ancre dans une volonté de
            retranscrire la richesse et la finesse des mécanismes perceptifs permettant à la plante de
            vivre en s’adaptant à son environnement. Pour cette raison, deux chercheurs, Frédérick
            Garcia et Adelin Barbacci, ont travaillé avec des artistes, afin de donner à voir un
            nouveau regard sur le végétal.
            </Typography>
            <Typography variant="body1">
            La particularité de cette exposition provient du fait qu’une multiplicité de compétences
            s’est réunie. Des spécialistes de la biomécanique, des systèmes d’apprentissage, le
            chercheur Yves Duthen, un preneur de son audio naturaliste: Fernand Deroussen, des
            programmeurs, des spécialistes des arts,de la lumière et des sons: Edwige Armand,
            Thierry Besche, ont tous oeuvré à construire cette installation art/science.
            «Qui parle donc ?» est une exposition écrite et créée à plusieurs mains qui invite le
            public à porter un autre regard sur le monde végétal qui nous entoure.
            </Typography>
            <div className="spacer"></div>
            <Grid container justify='center'>
                <Grid item xs={12}>
                <Ratio>
                    <iframe title="quiparleteaser" src="https://player.vimeo.com/video/484183371" width="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </Ratio>
                </Grid>
            </Grid>
            <Typography variant="h3">
            Conception et création:
            </Typography>
            <Typography variant="body1">
            Edwige Armand, Artiste, art plastique, Inp-Purpan, Lara-seppia
            </Typography>
            <Typography variant="body1">
            Frédérick Garcia, Scientifique, INRAE - MIA
            </Typography>
            <Typography variant="body1">
            Thierry Besche, Artiste, compositeur de son &amp; coordination
            </Typography>
            <Typography variant="body1">
            Yves Duthen, Scientifique, REVA-IRIT
            </Typography>
            <Typography variant="body1">
            Fernand Deroussen, prise de son audio naturaliste.
            </Typography>
            <Typography variant="body1">
            Adelin Barbacci, Scientifique, INRAE - LIPM
            </Typography>
            <Typography variant="body1">
            Avec la participation de :
            </Typography>
            <Typography variant="body1">
            Myriam Desainte-Catherine, Scientifique, Labri-Scrime
            </Typography>
            <Typography variant="h3">
            Les co-producteurs :
            </Typography>
            <Typography variant="body1">
            Une co-production de Passerelle Arts Sciences Technologies et des laboratoires :
            </Typography>
            <Typography variant="body1">
            MIA et LIPM de l’INRAE de Toulouse, REVA de l’IRIT /Université Toulouse Capitole,
            </Typography>
            <Typography variant="body1">
            LABRI-SCRIME et Festival FACTS / Université de Bordeaux – ENSEIRB
            </Typography>
            <Typography variant="body1">
            Avec le soutien de la Région Occitanie, du DREST - Ministère de la Culture, Toulouse INP Purpan.
            </Typography>
            <div className="spacer"></div>
            <Typography variant="h3">
            Voir le webdoc :
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <a href="https://webdocs.artecom.studio/QPD2/index.html" target="_blank" rel="noreferrer">
                    <AfficheImage affiche='/qui-parle_webdoc.jpg' paddingBottom={66.17}/>
                    </a>
                </Grid>
                <Grid item xs={8}>
                    <Typography variant="body1">
                    Cherchez les indices en mode Exploratoire pour découvrir des contenus inédits.
                    Un accès direct à certain contenu se fait en mode Navigation rapide (Dans le hall
                    d’entrée, porte de gauche).
                    </Typography>
                    <Typography variant="body1">
                        <a href="https://webdocs.artecom.studio/QPD2/index.html" target="_blank" rel="noreferrer">Voir en ligne</a>
                    </Typography>
                </Grid>
            </Grid>
            <div className="spacer"></div>
            <Typography variant="h3">
            Télécharger :
            </Typography>
            <Typography variant="body1">
                <a href="/assets/Present_QPDWeb_cpr.pdf" target="_blank" rel="noreferrer">Dossier de présentation de <strong>« Qui parle donc ? »</strong></a>
            </Typography>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <Divider/>
            <div className="spacer" id="atmosphere"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>

            {/*Atmosphère primale*/}
            <Grid container justify='left'>
                <Grid item xs={4}>
                    <AfficheImage affiche='/atmosphere.jpg' paddingBottom={66.17}/>
                </Grid>
            </Grid>
            <div className="spacer"></div>
            <Typography variant="body1">
            La crise pandémique du Covid-19 nous a permis de
            matérialiser les conséquences des activités humaines endommageant le développement
            de la biodiversité et générant de la pollution. L’arrêt de notre trop forte présence (sans
            tomber dans un anti-humanisme) sur l’environnement a ouvert la possibilité de réfléchir
            sur les raisons de notre comportement que l’on pourrait qualifier d’irresponsable voire
            de destructeur dans cette ère où l’homme à autant de force que les forces géologiques et
            où nous sommes à la 6eme extinction massive (il faut souligner que 65% de la biomasse
            à disparue en quelques décennies et que 5 espèces de plantes meurent chaque jour
            depuis 40 ans tandis qu’une espèce mourrait seulement tous les 27 sur une période de 4
            millions d’années).
            </Typography>
            <Typography variant="body1">
            Cette installation bio-technique propose de voir, de sentir la beauté et l’humilité des
            premières plantes sur terre (via des différents diffuseurs de gaz mais aussi la
            monstration des premières plantes). Le spectateur pourra visualiser via un œil de judas,
            ces plantes premières qui ont permis la transformation atmosphérique et il sera
            également immergé dans un espace sonore tenant compte de la particularité de
            l’atmosphère de l’ère Silurienne à l’ère Dévonienne. L’atmosphère primitive est
            essentiellement composée de dioxyde d’azote mais aussi de méthane et d’ammoniac ;
            nous simulerons la diffusion de ces gaz par le son et des indicateurs de gaz qui varieront
            en fonction de la présence du public.
            </Typography>
            <Typography variant="body1">
            Peut-être est-ce une voie proposée pour pouvoir un jour se mettre à penser à leur
            échelle et repenser l’importance que ces plantes ont eu pour créer l’atmosphère que tout
            être partage.
            </Typography>
            <div className="spacer"></div>
            <Grid container justify='center'>
                <Grid item xs={12}>
                    <AfficheImage affiche='/PlanteFicheLumiere_small.jpg'/>
                    <Typography variant="caption">© Lauriane Heissat</Typography>
                </Grid>
            </Grid>
            <Typography variant="h3">
            Equipe  de création et de réalisation :
            </Typography>
            <Typography variant="body1">
            Conception et coordination : Edwige Armand, Enseignante-Chercheuse Toulouse INP Purpan, Lara-Seppia (UT Jean Jaurès)
            </Typography>
            <Typography variant="body1">
            Conception : Catherine Jeandel, Directrice de Recherche, LEGOS (CNRS, CNES, IRD, UT3 - Paul Sabatier)
            </Typography>
            <Typography variant="body1">
            Création végétale : Coralie Breton, Assistante Recherche INP Purpan, Lauriane Heissat
            </Typography>
            <Typography variant="body1">
            Conception vie artificielle : Yves Duthen, REVA – IRIT (CNRS - UT Capitole)
            </Typography>
            <Typography variant="body1">
            Création numérique et réalisation technique : Franck Jubin, Programmeur
            </Typography>
            <Typography variant="body1">
            Conception : Frédérick Garcia, MIA – INRAE Occitanie
            </Typography>
            <Typography variant="body1">
            Création sonore : Thierry Besche, Créateur Sonore, Passerelle Arts Sciences
            Technologies
            </Typography>
            <Typography variant="body1">
            Recherche documentaire : Fanny Duquenne, Documentaliste INP Purpan
            </Typography>
            <Typography variant="body1">
            Création lumière : Cédric Cambon, concepteur lumière
            </Typography>
            <Typography variant="body1">
            Réalisation et création chimique : Frédéric Violleau, Enseignant-Chercheur et Directeur
            adjoint de la Recherche Toulouse INP Purpan, Laboratoire de Chimie Agro-industrielle à
            Toulouse
            </Typography>
            <Typography variant="body1">
            Ressources scientifiques : Antoine Flores, Etudiant 4eme Année Toulouse INP Purpan
            </Typography>
            <div className="spacer"></div>
            <Typography variant="h3">
            Partenaires :
            </Typography>
            <Typography variant="body1">
            - Passerelle Arts Sciences Technologies
            </Typography>
            <Typography variant="body1">
            - UP de recherche de Toulouse INP Purpan (Institut National Polytechnique de
            Toulouse)
            </Typography>
            <Typography variant="body1">
            - LEGOS (CNRS, CNES, IRD, UT3 - Paul Sabatier)
            </Typography>
            <Typography variant="body1">
            - LARA-SEPPIA (UT Jean Jaurès)
            </Typography>
            <Typography variant="body1">
            - REVA – IRIT (CNRS - UT Capitole)
            </Typography>
            <Typography variant="body1">
            - MIA – INRAE Occitanie
            </Typography>
            <div className="spacer"></div>
            <Typography variant="h3">
            À propos d’Atmosphère Primale
            </Typography>
            <div className="spacer"></div>
            <Grid container spacing={5}>
                <Grid item xs={6}>
                    <Ratio>
                        <iframe title="edwige" src="https://player.vimeo.com/video/484197179" width="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    </Ratio>
                    <Typography variant="body1">
                    <strong>Antoine Flores</strong>, étudiant en 4eme Année à Toulouse INP Purpan, nous parle des
                    différentes périodes atmosphériques et les conséquences sur le développement du végétal.
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Ratio>
                        <iframe title="edwige" src="https://player.vimeo.com/video/484186855" width="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    </Ratio>
                    <Typography variant="body1">
                    <strong>Edwige Armand</strong>, Enseignante-Chercheuse en Art et Culture générale à Toulouse INP Purpan
                    nous parle du projet Art / Science « Atmosphère Primale ».

                    </Typography>
                </Grid>
            </Grid>
            <div className="spacer"></div>
            <Grid container spacing={5}>
                <Grid item xs={6}>
                    <Ratio>
                        <iframe title="edwige" src="https://player.vimeo.com/video/484199112" width="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    </Ratio>
                    <Typography variant="body1">
                    <strong>Lauriane Heissat</strong>, création végétale, nous parle du choix des espèces végétales pour
                    Atmosphère Primale.
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Ratio>
                        <iframe title="edwige" src="https://player.vimeo.com/video/484203784" width="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    </Ratio>
                    <Typography variant="body1">
                    <strong>Catherine Jeandel</strong>, Directrice de recherche au CNRS, Laboratoire d&#39;études en
                    géophysique et océanographie spatiales (LEGOS), nous parle du lien entre océan,
                    atmosphère et vivant
                    </Typography>
                </Grid>
            </Grid>


            <div className="spacer"></div>
            <Typography variant="h3">
            Télécharger :
            </Typography>
            <Typography variant="body1">
                <a href="/assets/PresentAtmosp_PrimalV2.pdf" target="_blank" rel="noreferrer">Dossier de présentation de <strong>« Atmosphère Primale »</strong></a>
            </Typography>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <Divider/>
            {/*<div className="spacer" id='questions'></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>*/}


            {/*Questions*/}
            {/*<Typography variant="h2">
            Vous avez des questions ?
            </Typography>
            <div className="spacer"></div>
            <Typography variant="body1">
            Vous souhaitez des renseignements sur les projets ?
            </Typography>
            <Typography variant="body1">
            &gt; posez les sur notre Chat de 19h à 22h
            Thierry Besche, coordinateur de Passerelle Arts Sciences Technologies vous répond
            </Typography>
            <div className="spacer"></div>
            <iframe title="chat" className="nec-chat" src="https://tawk.to/chat/5fbe93d1920fc91564caa3d7/default"/>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <Divider/>*/}
            <div className="spacer" id='passerelle'></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>

            {/*Passerelle*/}
            <Grid container justify='left'>
                <Grid item xs={4}>
                    <AfficheImage affiche='/passerelle.jpg' paddingBottom={66.17}/>
                </Grid>
            </Grid>
            <div className="spacer"></div>
            <Typography variant="h2">
            Présentation de passerelle
            </Typography>
            <Typography variant="body1">
            Passerelle Arts Sciences Technologies
            </Typography>
            <Typography variant="body1">
            En région Occitanie, l’association Passerelle Arts Sciences Technologies a été créée en
            mars 2016. Elle réunit des acteurs engagés à titre personnel, artistes et universitaires,
            représentants d’institutions, d’associations ou d’entreprises dans l’objectif de
            développer des relations entre les arts, les sciences, les technologies et la société.
            Passerelle s’engage dans la production, la diffusion de projets de création artistique en
            lien avec le domaine scientifique et les technologies numériques qui mettent en exergue
            les problématiques actuelles d’ordre artistique, esthétique autant que sociétal.
            Passerelle œuvre à l’émergence de Territoires Arts Sciences en Occitanie, espace de
            recherche et d’application, de formation et de création, de mise en réseau dans une
            dynamique transversale et pluridisciplinaire.
            </Typography>
            <Typography variant="body2">
            Passerelle est membre du réseau <a href="https://www.reseau-tras.eu/" target="_blank" rel="noreferrer">TRAS</a> (Transversale des Réseaux Arts Sciences) et de <a href="https://hacnum.org/accueil/" target="_blank" rel="noreferrer">HACNUM</a> (Le réseau des Arts Hybrides et Numériques).
            </Typography>
            <Typography variant="body1">
            <a href="mailto:contact@passerelle-arts-sciences.net">contact@passerelle-arts-sciences.net</a>
            </Typography>
            <Typography variant="body1">
            <a href="https://www.passerelle-arts-sciences.fr" target="_blank" rel="noreferrer">www.passerelle-arts-sciences.fr</a>
            </Typography>
            <div className="spacer"></div>
            <Grid container justify='left' spacing={5}>
                <Grid item xs={4}>
                    <AfficheImage affiche='/culture.jpg' paddingBottom={66.17}/>
                </Grid>
                <Grid item xs={4}>
                    <AfficheImage affiche='/ndc.jpg' paddingBottom={66.17}/>
                </Grid>
            </Grid>
            <Typography variant="body1">
            La Nuit européenne des chercheur·e·s de Toulouse et Albi est un projet coordonné par l'Université fédérale Toulouse Midi - Pyrénées.
            </Typography>
            <Typography variant="body1">
            L'évènement est organisé à Albi par IMT-Mines Albi et l'INU Champollion, en collaboration étroite avec leurs partenaires.
            </Typography>
            <Typography variant="body1">
            La Nuit européenne des chercheur·e·s est soutenue par la Commission Européenne dans le cadre de programme HORIZON 2020 Action Marie Sklodowska-Curie.
            </Typography>

            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="spacer last"></div>

        </Container>
        <div className={classes}>
        <AppBar>
          <Toolbar>
            <Grid container spacing={3}>
            <Grid item xs align="center"><a className='menu' href='#rendez-vous'>Les rendez-vous en direct</a></Grid>
            <Grid item xs align="center"><a className='menu' href='#qui-parle-donc'>Qui parle donc ?</a></Grid>
            <Grid item xs align="center"><a className='menu' href='#atmosphere'>Atmosphère Primale</a></Grid>
            {/*<Grid item xs align="center"><a className='menu' href='#questions'>Vous avez des questions ?</a></Grid>*/}
            <Grid item xs align="center"><a className='menu' href='#passerelle'>Présentation de Passerelle</a></Grid>
        </Grid>
          </Toolbar>
        </AppBar>
        </div>
        </ThemeProvider>;
    }
}

export default App;
