import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Image from 'components/Image';


/* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    inner: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
    dark: {
        opacity: 0.3,
        transition: "opacity 0.2s"
    }
}))

/**
 *
 *
 */
const AfficheImage = ({ affiche = null, paddingBottom = 56.25, dark = false, background= '#333' }) => {
    const classes = useStyles()
    if (affiche) {
        return <div className={classes.root} style={{ paddingBottom: paddingBottom + "%", backgroundColor:background}}>
            <div className={classes.inner}>
                <Image src={affiche} />
            </div>
        </div>
    }
    else return <div className={classes.root}>
        <div className={classes.inner}>
        </div>
    </div>
}


export default AfficheImage;
